import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_SELECTED_CLIENT,
  CREATE_CLIENT_SUCCESS,
  EDIT_CLIENT_SUCCES,
  GET_CLIENTS_SUCCESS,
  GET_CLIENT_BY_ID_SUCCESS,
} from 'redux/actions/clients';
import { IChannels } from 'types/api/channels';
import { IDocuments } from 'types/api/documents';
import { ITags } from 'types/api/tags';
import { IVacancies } from 'types/api/vacancies';

export type IDecisionMaker = {
  _id: string;
  createdAt: string;
  firstNameLatin?: string;
  lastNameLatin?: string;
  role?: string;
  email?: string;
};

export enum clientStatusesEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type Channel = {
  _id: string;
  channel: string;
  refersTo: string;
  teamId: string;
};

export type IClient = {
  _id: string;
  teamId: string;
  name: string;
  webSite: string;
  decisionMakers: IDecisionMaker[];
  channel: IChannels;
  linkedIn: string;
  country: string;
  city: string;
  status: clientStatusesEnum;
  assignedTo: string;
  vacancies: IVacancies[];
  tags: ITags[];
  avatars: string[];
  currentAvatar: string;
  documents: IDocuments[];
  otherLinks: string[];
  createdAt: string;
  updatedAt: string;
};

type initialStateType = {
  clients: IClient[] | null;
  selectedClient: IClient | null;
};

const initialState: initialStateType = {
  clients: null,
  selectedClient: null,
};

const handlers = {
  [GET_CLIENTS_SUCCESS.type]: (prevState: initialStateType, { payload }: PayloadAction<IClient[]>) => {
    return {
      ...prevState,
      clients: payload,
    };
  },
  [GET_CLIENT_BY_ID_SUCCESS.type]: (prevState: initialStateType, { payload }: PayloadAction<IClient>) => {
    return {
      ...prevState,
      selectedClient: payload,
    };
  },
  [CREATE_CLIENT_SUCCESS.type]: (prevState: initialStateType, { payload }: PayloadAction<IClient>) => {
    return {
      ...prevState,
      selectedClient: payload,
    };
  },
  [EDIT_CLIENT_SUCCES.type]: (prevState: initialStateType, { payload }: PayloadAction<IClient>) => {
    return {
      ...prevState,
      selectedClient: payload,
    };
  },
  [CLEAR_SELECTED_CLIENT.type]: (prevState: initialStateType) => {
    return {
      ...prevState,
      selectedClient: null,
    };
  },
};

export default createReducer(initialState, handlers);
