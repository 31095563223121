import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { ApiService } from '../middleware/api';
import { IDecisionMaker, clientStatusesEnum } from '../reducers/clients';

type ClientToCreate = {
  name: string;
  webSite?: string;
  decisionMakers?: IDecisionMaker[];
  channel?: string;
  linkedIn?: string;
  country?: string;
  city?: string;
  status: clientStatusesEnum;
  assignedTo: string;
  vacancies?: string[];
  tags?: string[];
  avatars?: string[];
  currentAvatar?: string;
  documents?: string[];
  otherLinks?: string[];
};

type GetAllClientsActionType = {
  onFailure?: (error: string) => void;
};
export const GET_CLIENTS_SUCCESS = createAction('clients/GET_CLIENTS.SUCCESS');
export const getAllClients =
  ({ onFailure }: GetAllClientsActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'clients/all',
      });

      dispatch(GET_CLIENTS_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };

type GetClientByIdActionType = {
  clientId: string;
  onFailure?: (error: string) => void;
};
export const GET_CLIENT_BY_ID_SUCCESS = createAction('clients/GET_CLIENT_BY_ID.SUCCESS');
export const getClientById =
  ({ clientId, onFailure }: GetClientByIdActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `clients/${clientId}`,
      });
      dispatch(GET_CLIENT_BY_ID_SUCCESS(data));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      onFailure?.(errorMessage);
    }
  };

type CreateClientActionType = {
  clientData: ClientToCreate;
  clientDocuments: File[];
  clientAvatar: File | null;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const CREATE_CLIENT_SUCCESS = createAction('clients/CREATE_CLIENT.SUCCESS');
export const createClient =
  ({ clientData, clientDocuments, clientAvatar, onSuccess, onFailure }: CreateClientActionType) =>
  async (dispatch: AppDispatch) => {
    const formData = new FormData();

    if (clientAvatar) {
      formData.append('avatar', clientAvatar);
    }

    for (let i = 0; i < clientDocuments.length; i++) {
      formData.append('documents', clientDocuments[i]);
    }

    const keys = Object.keys(clientData);
    Object.values(clientData).forEach((value: any, index) => {
      if (Array.isArray(value) && typeof value[0] === 'object') {
        const objKeys = Object.keys(value[0]);

        Object.values(value[0]).forEach((val: any, index) => {
          formData.append(objKeys[index], val);
        });
      }

      formData.append(keys[index], value);
    });

    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `clients`,
        query: formData,
      });

      dispatch(CREATE_CLIENT_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      onFailure(errorMessage);
    }
  };

type EditClientActionType = {
  clientId: string;
  clientData: any;
  clientDocuments: File[];
  clientAvatar: File | null;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};

export const EDIT_CLIENT_SUCCES = createAction('clients/EDIT_CLIENT.SUCCESS');
export const editClient =
  ({ clientId, clientData, clientDocuments, clientAvatar, onSuccess, onFailure }: EditClientActionType) =>
  async (dispatch: AppDispatch) => {
    const formData = new FormData();

    if (clientAvatar) {
      formData.append('avatar', clientAvatar);
    }

    if (clientDocuments.length) {
      for (let i = 0; i < clientDocuments.length; i++) {
        formData.append('documents', clientDocuments[i]);
      }
    }

    const keys = Object.keys(clientData);
    Object.values(clientData).forEach((value: any, index) => {
      if (typeof value === 'object') {
        const objKeys = Object.keys(value);
        Object.values(value).forEach((val: any, index) => formData.append(objKeys[index], val));
      }

      formData.append(keys[index], value);
    });

    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `clients/${clientId}`,
        query: formData,
      });

      dispatch(EDIT_CLIENT_SUCCES(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      onFailure(errorMessage);
    }
  };

type ChangeClientStatusActionType = {
  clientId: string;
  status: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const CHANGE_CLIENT_STATUS_SUCCES = createAction('clients/CHANGE_CLIENT_STATUS.SUCCESS');
export const changeClientStatus =
  ({ clientId, status, onSuccess, onFailure }: ChangeClientStatusActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PATCH',
        endpoint: `clients/status/${clientId}`,
        query: { status },
      });

      dispatch(CHANGE_CLIENT_STATUS_SUCCES(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

type DeleteClientAvatarActionType = {
  clientId: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const DELETE_CLIENT_AVATAR_SUCCESS = createAction('clients/DELETE_CLIENT_AVATAR.SUCCESS');
export const deleteClientAvatar =
  ({ clientId, onSuccess, onFailure }: DeleteClientAvatarActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `clients/${clientId}/avatar`,
      });

      dispatch(DELETE_CLIENT_AVATAR_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

export const CLEAR_SELECTED_CLIENT = createAction('clients/CLEAR_SELECTED_CLIENT');
export const clearSelectedClient = () => (dispatch: AppDispatch) => {
  dispatch(CLEAR_SELECTED_CLIENT());
};
